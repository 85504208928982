<template>
  <Modal :is-modal-open="isModalOpen" :is-show-close="true" @close="close">
    <template #body>
      <div class="remove-drawing__confirmation">
        <div class="title">{{ $t('message.headPaneMenu.junction.removeDrawing.title') }}</div>
        <div class="buttons">
          <simple-button color="red" @clickedFromSimpleBtn="confirm">
            {{ $t('message.headPaneMenu.junction.removeDrawing.confirm') }}
          </simple-button>
          <simple-button color="green" @clickedFromSimpleBtn="cancel">
            {{ $t('message.headPaneMenu.junction.removeDrawing.cancel') }}
          </simple-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  name: 'RemoveDrawingConfirmation',
  components: {
    SimpleButton: () => import('@/components/elements/Dom/Simple-button'),
    Modal: () => import('@/components/elements/Modals/Modal')
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="sass">
.remove-drawing__confirmation
  max-width: rem(400)
  padding: rem(40) rem(25)
  box-sizing: border-box
  .title
    color: $black
    font-size: rem(16)
    font-weight: bold
  .buttons
    display: flex
    +media((align-items: (400: center)))
    +media((flex-direction: (320: column, 400: row)))
    margin-top: rem(20)
    .simple-btn
      height: rem(40)
      &:not(:last-child)
        +media((margin: (320: 0 0 rem(6), 400: 0 rem(6) 0 0)))
</style>
